@import "common";

.team-page-wrapper {
  padding-bottom: $footer-padding-exact + 10;

  .Navigator {
    height: 100%;
    width: 80%;
    padding: 0 10%;
    .left {
      position: fixed;
      top: 50%;
      left: 2%;
    }
    .right {
      position: fixed;
      top: 50%;
      right: 2%;
    }
    .team {
      padding-top: 30px;
      .team-top {
        display: flex;
        justify-content: space-evenly;
        .image {
          min-height: 500px;
          img {
            max-height: 500px;
          }
        }
        .details {
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          max-width: 400px;
          .details-title {
            font-size: 1.5rem;
            font-weight: 600;
          }
          .details-year {
            font-weight: 500;
          }
        }

        @include cdMax(800) {
          flex-direction: column;
          justify-content: center;
          .image {
            img {
              margin: 0 5%;
              max-width: 90%;
            }
          }
          .details {
            margin: 10% 15%;
            max-width: 70%;
            min-height: 32vh;
          }
        }
      }
      .team-links {
        padding-top: 50px;
        display: flex;
        text-align: center;
        justify-content: space-evenly;
        .link {
          cursor: pointer;
          a {
            color: black;
            text-decoration: none;
          }
        }
      }
    }
  }
  @include cdMax(800) {
  }
}
.modal-content {
  .theme-modal {
    height: 90vh;
    iframe {
      width: 80%;
      height: 80%;
      margin: 5% 10%;
    }

    @include cdMax(800) {
      iframe {
        height: 45%;
        width: 90%;
        margin: 5%;
      }
    }
  }
  .rulebook-modal {
    height: 90vh;
    margin: 0;
    padding: 0;
  }
  .team-modal {
    display: flex;
    padding: 20px;
    justify-content: center;
    text-align: center;
    justify-content: space-evenly;
    font-size: 2rem;
    font-weight: 800;
    .member {
      font-size: 1.2rem;
      padding-top: 10px;
      font-weight: 500;
    }
  }
  .wiki-modal {
    height: 90vh;
    margin: 0;
    padding: 0;
    iframe {
      height: 100%;
      width: 100%;
    }
  }
}
