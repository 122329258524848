@import "common";

.robocon-wrapper {
  display: block;
  margin: 0 30px;
  .robocon-header {
    padding-top: 25px;
    font-size: 2.5em;
    font-weight: 700;
  }
  .robocon-content {
    font-size: 1.2rem;
    text-align: center;
    a {
      text-decoration: none;
    }
    img {
      padding: 40px 0;
      margin: 0 auto;
      width: 80%;
    }
  }
  .links {
    padding-top: 40px;
    display: flex;
    justify-content: center;
    a {
      padding: 10px 20px;
    }
  }

  @include cdMax(800) {
    margin: 0 25px;
    .robocon-header {
      font-size: 2em;
    }
    .robocon-content {
      img {
        width: 100%;
        padding: 10px 0;
      }
    }
  }
}
