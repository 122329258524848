@import "common";

.contact-page-wrapper {
  padding-bottom: $footer-padding-exact;
}

.contact-wrapper {
  width: 100%;
  min-height: 700px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  .contact-form-wrapper {
    width: 30%;
    .form-wrapper {
      .form-details {
        .form-field {
          margin: 20px 0;
        }
      }
      .form-button {
        width: 100%;
        background-color: $secondary-color;
        color: $theme-color;
        font-weight: 500;
        font-size: 20px;
        box-shadow: $boxshadow;
        padding: 14px;
        margin: 14px 0;
        outline: none;
        border: none;
        transition: color 0.5s, background-color 0.5s;
        border-radius: 1px;
        cursor: pointer;
        &:hover {
          background-color: $theme-color;
          color: $secondary-color;
        }
      }
    }
  }
  .contact-image {
    max-width: 70%;
    transform: rotate(16deg);
    .mail-image {
      max-width: 250px;
      max-height: 250px;
      padding: 20px;
    }
  }

  @include cdMax(960) {
    min-height: auto;
    flex-direction: column;
    .contact-form-wrapper {
      order: 1;
      width: 80%;
      padding: 10px;
      .form-wrapper {
        .form-details {
          .form-field {
            margin: 10px 0;
          }
        }
      }
    }
    .contact-image {
      order: 0;
      max-width: none;
      margin-top: 80px;
      .mail-image {
        max-width: 200px;
        max-height: 200px;
      }
    }
  }
}

.contact-cards-wrapper {
  background-color: azure;
  text-align: center;
  min-height: 600px;
  .contact-header {
    margin: 0;
    padding: 25px 0;
  }
  .contact-cards {
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    .contact-card {
      margin: 3%;
      margin-bottom: 7%;
      width: 24%;
      .image {
        max-width: 150px;
      }
      .contact-no {
        width: max-content;
        margin: auto;
      }
    }
    .contact-buttons {
      display: flex;
      justify-content: flex-start;
      padding: 10px;
      a {
        text-decoration: none;
      }
    }
  }

  @include cdMax(1280) {
    .contact-cards {
      .contact-card {
        width: 30%;
      }
    }
  }

  @include cdMax(960) {
    .contact-cards {
      flex-direction: column;
      margin: auto;
      .contact-card {
        width: auto;
      }
      .contact-card:last-child {
        margin-bottom: 90px;
      }
      .contact-card:first-child {
        margin-top: 10%;
      }
    }
  }
}
