@import "common";

.errorpage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // height: 100vh;
  .error-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .error-details {
      border-right: 3px solid #c8c8c8;
      padding: 40px;
      .code {
        text-align: center;
        font-size: 100px;
        font-weight: 900;
        margin: 0;
      }
      .error-message {
        color: #555;
        text-align: center;
        font-size: 23px;
        font-weight: 500;
        margin: 0;
      }
    }
    .error-lost {
      padding: 40px;
      max-width: 150px;
    }
  }

  @include cdMax(960) {
    .error-wrapper {
      flex-direction: column;
      .error-details {
        padding: 20px;
        border-top: 3px solid #c8c8c8;
        border-right: none;
        order: 1;
        .code {
          font-size: 60px;
        }
        .error-message {
          font-size: 14px;
        }
      }
      .error-lost {
        order: 0;
        padding: 20px;
        max-width: 100px;
      }
    }
  }
}
