@import "common";

.about-page-wrapper {
  padding-bottom: $footer-padding - 25px;
  min-height: 790px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .about-details {
    height: 100%;
    max-width: 50%;
  }
  .about-map {
    width: 50%;
    background-color: #eee;
  }

  @include cdMax(960) {
    height: auto;
    flex-direction: column;
    justify-content: center;
    min-height: auto;
    .about-details {
      max-width: none;
      width: 100%;
      height: auto;
    }
    .about-map {
      height: 300px;
      width: 100%;
    }
  }
}

.about-details-wrapper {
  padding: 20px;
  margin: 0 auto;
  .about-title {
    color: $theme-color;
    text-align: center;
    letter-spacing: 0.036em;
    font-weight: 700;
  }
  .about-subtitle {
    padding: 0 50px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.08em;
    color: #b29758;
  }
  .about-description {
    padding: 0 50px;
    line-height: 1.5em;
    font-size: 18px;
    font-weight: 300;
    text-align: center;
    color: #5b5f7c;
  }
  .about-us {
    padding: 25px;
  }
  .about-college {
    padding: 25px;
  }

  @include cdMax(960) {
    padding: 8px;
    .about-title {
      font-size: 25px;
    }
    .about-subtitle {
      padding: 0;
      font-size: 16px;
    }
    .about-description {
      padding: 0 00px;
      text-align: start;
      line-height: 1.25em;
      font-size: 16px;
    }
    .about-us {
      padding: 12px;
    }
    .about-college {
      padding: 12px;
    }
  }
}

// map section start
.map-wrapper {
  width: 100%;
  height: 100%;
  .map-embed {
    width: 100%;
    height: 100%;
  }
}
// map section end
